/* eslint-disable import/no-internal-modules */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable max-len */
import { ApiService } from '@/shared/utils/api-service';
const apiUrl = 'https://lobster-app-6o5ni.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class UserApiService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    // ПОЛУЧИТЬ ЮЗЕРА
    async getUser(id) {
        return this.apiService.get('user', { referralId: id });
    }
    // ПРОВЕРКА ЮЗЕРА НА ПОДПИСКИ
    async checkUserSubscribes() {
        return this.apiService.get('user/subscribeCheck', {});
    }
    // ПОЛУЧИТЬ СПИСОК СВОИХ РЕФЕРРАЛОВ
    async getReferrals() {
        return this.apiService.get('user/referrals', {});
    }
}
