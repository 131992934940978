import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import { useTonAddress, useTonConnectUI } from 'delab-tonconnect-ui-react';
import { useUnit } from 'effector-react';
import { Address, toNano } from '@ton/core';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getPrivateBalance, getUserData, getWithdrawHistory, userState, withdrawState, } from '@/features';
import { WithdrawService } from '@/entities';
import { Button, BtnVariant, Input, Text, CountdownTimer, sendJettonToBoc, resolveJettonAddressFor, } from '@/shared';
import s from './withdrawForm.module.scss';
export const WithdrawForm = () => {
    const [withdrawForm, setWithdrawForm] = useState({
        amount: '',
        walletAddress: '',
    });
    const { user } = useUnit(userState);
    const rawAddress = useTonAddress();
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const [isSending, setIsSending] = useState(false);
    const { withdrawHistory } = useUnit(withdrawState);
    useEffect(() => {
        if (!rawAddress)
            return;
        setWithdrawForm({
            ...withdrawForm,
            walletAddress: rawAddress,
        });
    }, [rawAddress]);
    // setOptions({
    //     walletsListConfiguration: {
    //         includeWallets: [
    //             {
    //                 appName: 'dewallet',
    //                 name: 'DeWallet',
    //                 imageUrl: 'https://avatars.githubusercontent.com/u/116884789?s=200&v=4',
    //                 aboutUrl: 'https://wallet.tg/',
    //                 universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
    //                 bridgeUrl: 'https://sse-bridge.delab.team/bridge',
    //                 platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
    //             },
    //         ],
    //     },
    // });
    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'amount') {
            let inputValue = value.replace(/,/g, '.');
            inputValue = inputValue.replace(/^0+(?!$|\.)/, '');
            inputValue = inputValue.replace(/[^0-9.]+/, '');
            inputValue = inputValue.length === 0 ? '0' : inputValue;
            const isValidInput = /^-?\d*\.?\d*$/.test(inputValue);
            if (isValidInput) {
                setWithdrawForm({ ...withdrawForm, amount: value });
            }
        }
        setWithdrawForm({ ...withdrawForm, [name]: value });
    };
    const withdrawApi = new WithdrawService();
    async function sendTrans(id_user, currency, amount) {
        if (!rawAddress)
            return false;
        const addressWallet = 'UQAxkxCBgsUfg4h7Swq6KBR_byT9mCL4oKtp8Y_tbdzKAPtO';
        try {
            const payload = sendJettonToBoc({
                to: addressWallet,
                amount: toNano(amount).toString(),
                comment: id_user.toString(),
            }, addressWallet);
            const jettonWallet = await resolveJettonAddressFor(currency.toString(), Address.parse(rawAddress).toString());
            if (!jettonWallet) {
                console.error('NO jettonWallet ADDRESS');
                return;
            }
            const tr = {
                validUntil: Math.floor(Date.now() / 1000) + 60,
                messages: [
                    {
                        address: Address.parse(addressWallet).toString(),
                        amount: toNano(0.2).toString(),
                    },
                ],
            };
            const tx = await tonConnectUI.sendTransaction(tr);
            // return tx
            return true;
        }
        catch (e) {
            console.log(e);
            return false;
        }
    }
    const startOfDay = moment().tz('Europe/Moscow').startOf('day').valueOf();
    const startOfDayUnix = Math.floor(startOfDay / 1000);
    const withdrawsToday = withdrawHistory?.filter((withdraw) => {
        const createdAtUnix = parseInt(withdraw.createdAt) / 1000;
        return createdAtUnix >= startOfDayUnix;
    });
    const withdrawalsCount = withdrawsToday?.reduce((acc, el) => (acc += el.tokenAmount), 0);
    const handleSubmit = async () => {
        if (isSending)
            return;
        const isValidAddress = Address.isRaw(Address.parseFriendly(withdrawForm?.walletAddress).address.toRawString());
        if (!isValidAddress) {
            return alert('Not valid address');
        }
        try {
            setIsSending(true);
            if ((withdrawalsCount ?? 0) + Number(withdrawForm?.amount) > (user?.limit ?? 0)) {
                toast(`Limit: ${user?.limit} PRIVATE per 24h`, {
                    type: 'error',
                    autoClose: 1800,
                    draggable: true,
                    bodyStyle: { background: '#E51D41' },
                    style: { background: '#E51D41' },
                });
                setIsSending(false);
                return;
            }
            const isDeWallet = localStorage.getItem('ton-connect-ui_preferred-wallet');
            if (isDeWallet !== 'dewallet') {
                const tx = await sendTrans(window.Telegram.WebApp.initDataUnsafe.user.id, Address.parse('0:37042dbb5cd7a29e5405bef4108a9304a132eea54b210244956f45b5dbd4c7fc'), Number(0.3));
                if (!tx) {
                    setIsSending(false);
                    toast(`Withdrawal rejected`, {
                        type: 'error',
                        autoClose: 1800,
                        draggable: true,
                        bodyStyle: { background: '#E51D41' },
                        style: { background: '#E51D41' },
                    });
                    return;
                }
            }
            const res = await withdrawApi.createWithdraw(Number(withdrawForm.amount), withdrawForm.walletAddress);
            toast('Withdrawal request created successfully', {
                type: 'success',
                autoClose: 1800,
                draggable: true,
                bodyStyle: { background: '#29A468' },
                style: { background: '#29A468' },
            });
            getUserData({ shouldSetLoading: false });
            getPrivateBalance(rawAddress ?? '');
            getWithdrawHistory();
            setWithdrawForm({ amount: '', walletAddress: rawAddress ?? '' });
            setIsSending(false);
        }
        catch (error) {
            if (error.response.data.error === `Failed  Error: Maximum ${user?.limit} private in day`) {
                toast(`Limit: ${user?.limit} PRIVATE per 24h`, {
                    type: 'error',
                    autoClose: 1800,
                    draggable: true,
                    bodyStyle: { background: '#E51D41' },
                    style: { background: '#E51D41' },
                });
            }
            setIsSending(false);
        }
    };
    return (_jsxs("div", { children: [_jsx(Text, { className: s.text, children: "Enter the amount of PRIVATE to be credited to your balance within the app" }), _jsx(Text, { className: s.text, children: "Private withdrawals arrive within 2 to 48 hours." }), _jsxs(Text, { className: s.text, children: ["Limit: ", user?.limit, " PRIVATE per 24h"] }), _jsxs("div", { children: [_jsx(Input, { className: s.input, value: withdrawForm.amount, setValue: onChange, name: "amount", inputMode: "decimal", pattern: "[0-9]*[.,]?[0-9]*", placeholder: "amount of private", marginBottom: "16px" }), _jsxs(Text, { className: s.bal, children: ["Balance:", ' ', (user?.balance ?? 0) % 1 === 0
                                ? user?.balance.toLocaleString('en', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })
                                : user?.balance.toLocaleString('en', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }), ' ', "PRIVATE"] }), _jsx(Input, { className: s.input, value: withdrawForm.walletAddress, setValue: onChange, name: "walletAddress", placeholder: "wallet address" }), _jsxs("div", { className: s.innerInfo, children: [_jsxs("div", { className: s.innerInfoLimit, children: [_jsxs("span", { children: ["Limit: ", withdrawalsCount ?? 0] }), " / ", user?.limit, " PRIVATE"] }), _jsx(CountdownTimer, {})] }), _jsx(Button, { className: s.btn, variant: BtnVariant.green, disabled: !rawAddress
                            ? false
                            : Number(withdrawForm?.amount) > Number(user?.balance) ||
                                withdrawForm.amount === '0' ||
                                withdrawForm.amount === '' ||
                                Number(withdrawForm.amount) < 100 ||
                                isNaN(Number(withdrawForm.amount)), onClick: () => (rawAddress ? handleSubmit() : tonConnectUI.openModal()), isLoading: isSending, children: rawAddress
                            ? Number(withdrawForm?.amount) > Number(user?.balance)
                                ? 'Not enough balance'
                                : Number(withdrawForm.amount) < 100
                                    ? 'Minimum 100'
                                    : 'withdraw'
                            : 'connect' })] })] }));
};
