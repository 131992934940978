import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Address, toNano } from '@ton/core';
import { useTonAddress, useTonConnectUI } from 'delab-tonconnect-ui-react';
import { toast } from 'react-toastify';
import { useUnit } from 'effector-react';
import { Text, Input, Button, BtnVariant, resolveJettonAddressFor, sendJettonToBoc, } from '@/shared';
import { getPrivateBalance, getUserData, userState } from '@/features';
import { DepositHistory } from '../depositHistory';
import s from './despoitInner.module.scss';
export const DepositInner = () => {
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const rawAddress = useTonAddress();
    const { privateBalance, user } = useUnit(userState);
    useEffect(() => {
        if (rawAddress) {
            getPrivateBalance(rawAddress);
        }
    }, [rawAddress]);
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://avatars.githubusercontent.com/u/116884789?s=200&v=4',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    async function sendTrans(id_user, currency, amount) {
        if (!rawAddress)
            return false;
        const addressWallet = 'UQAxkxCBgsUfg4h7Swq6KBR_byT9mCL4oKtp8Y_tbdzKAPtO';
        try {
            setLoading(true);
            const payload = sendJettonToBoc({
                to: addressWallet,
                amount: toNano(amount).toString(),
                comment: id_user.toString(),
            }, addressWallet);
            const jettonWallet = await resolveJettonAddressFor(currency.toString(), Address.parse(rawAddress).toString());
            if (!jettonWallet) {
                console.error('NO jettonWallet ADDRESS');
                setLoading(false);
                return;
            }
            const tr = {
                validUntil: Math.floor(Date.now() / 1000) + 60,
                messages: [
                    {
                        address: Address.parse(jettonWallet).toString(),
                        amount: toNano(0.18).toString(),
                        payload,
                    },
                    {
                        address: Address.parse(addressWallet).toString(),
                        amount: toNano(0.2).toString(),
                    },
                ],
            };
            const tx = await tonConnectUI.sendTransaction(tr);
            // return tx
            toast('The deposit will be credited in the next few minutes', {
                type: 'success',
                autoClose: 1800,
                draggable: true,
                bodyStyle: { background: '#29A468' },
                style: { background: '#29A468' },
            });
            getUserData({ shouldSetLoading: false });
            getPrivateBalance(rawAddress ?? '');
            setLoading(false);
            return tx;
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return false;
        }
    }
    return (_jsxs("div", { children: [_jsx(Text, { className: s.info, children: "Enter the amount of PRIVATE to be credited to your balance within the app" }), _jsx(Input, { value: amount, setValue: (e) => {
                    let inputValue = e.target.value.replace(/,/g, '.');
                    inputValue = inputValue.replace(/^0+(?!$|\.)/, '');
                    inputValue = inputValue.replace(/[^0-9.]+/, '');
                    inputValue = inputValue.length === 0 ? '0' : inputValue;
                    const isValidInput = /^-?\d*\.?\d*$/.test(inputValue);
                    if (isValidInput) {
                        setAmount(inputValue);
                    }
                }, inputMode: "decimal", pattern: "[0-9]*[.,]?[0-9]*", placeholder: "amount of private", className: `${s.input} ${rawAddress && (Number(amount) > Number(privateBalance) ? s.inputError : '')}`, classNameInner: `${rawAddress && (Number(amount) > Number(privateBalance) ? s.inputError2 : '')}` }), _jsxs(Text, { className: s.bal, children: ["Balance:", ' ', (user?.balance ?? 0) % 1 === 0
                        ? user?.balance.toLocaleString('en', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                        : user?.balance.toLocaleString('en', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }), ' ', "PRIVATE"] }), _jsx(Button, { className: s.deposit, onClick: () => !rawAddress
                    ? tonConnectUI.openModal()
                    : sendTrans(window.Telegram.WebApp.initDataUnsafe.user.id, Address.parse('0:37042dbb5cd7a29e5405bef4108a9304a132eea54b210244956f45b5dbd4c7fc'), Number(amount)), variant: BtnVariant.green, disabled: !rawAddress
                    ? false
                    : Number(amount) === 0 ||
                        Number(amount) > Number(privateBalance) ||
                        isNaN(Number(amount)), isLoading: loading, children: rawAddress
                    ? Number(amount) > Number(privateBalance)
                        ? 'Insufficient funds'
                        : 'Deposit'
                    : 'Connect' }), _jsx(Button, { className: `${s.deposit} ${s.deposit2}`, onClick: () => window.open('https://dedust.io/swap/DFC/EQA3BC27XNeinlQFvvQQipMEoTLupUshAkSVb0W129TH_OIf', '_blank'), variant: BtnVariant.green, children: "Buy PRIVATE" }), _jsx(DepositHistory, {})] }));
};
