/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-cycle */
import axios from 'axios';
const apiUrl = 'https://lobster-app-6o5ni.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class StakingApiService {
    constructor() {
        this._url = apiUrl;
        this._headers = headers;
    }
    handleError(error) {
        console.error(error);
        return undefined;
    }
    async makeGetRequest(url, data) {
        try {
            const config = { params: data, headers: this._headers };
            const res = await axios.get(`${this._url}${url}`, config);
            return res.data;
        }
        catch (error) {
            return this.handleError(error);
        }
    }
    async makePostRequest(url, data) {
        const res = await axios.post(`${this._url}${url}`, data, { headers: this._headers });
        return res.data;
    }
    async get(url, data) {
        return this.makeGetRequest(url, data);
    }
    async post(url, data) {
        return this.makePostRequest(url, data);
    }
    // ПОЛУЧИТЬ СТЕЙКИНГИ ЮЗЕРА
    async stakeUser() {
        return this.get('stake', {});
    }
    // ПОЛУЧИТЬ ДОСТУПНЫЕ СТЕЙКИНГИ
    async availableStakes() {
        return this.get('stake/available', {});
    }
    // СДЕЛАТЬ НОВЫЙ ВКЛАД
    async newStake(amount, stakeId) {
        return this.post('stake', { amount, stakeId });
    }
    // ВЫВОДА СТЕЙКА
    async withdrawStake(stakeId) {
        return this.post('stake/withdraw', { stakeId });
    }
}
